import React from "react"
import { graphql } from "gatsby"
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'

import SEO from "../components/seo"
import Layout from "../components/layout"
import Container from '../components/container'

export default function BlogPost({data: {mdx}}) {
  const frontmatter = mdx.fields
  const {
    title,
    date,
    description,
    image,
    keywords,
  } = frontmatter

  return (
    <>
      <SEO title={title} description={description} image={image} keywords={keywords} article={true} />
      <Layout frontmatter={frontmatter}>
        <Container
          maxWidth={frontmatter.maxWidth}
          className="prose"
        >
          <h1 className="!mb-2">{title}</h1>
          <div className="mb-3 !text-gray-500">Published: {date}</div>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </Container>
      </Layout>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        title
        keywords
      }
    }
    mdx(fields: { id: { eq: $id } }) {
      fields {
        date
        slug
        title
        description
        image
        keywords
      }
      body
    }
  }
`
